import React from 'react';
import { VscHeartFilled } from 'react-icons/vsc';

const Footer = () => {
  return (
    <footer className="text-sm mt-5 py-4 flex flex-col items-center justify-center top-0">
      <p className="text-gray-700">
        Made with <VscHeartFilled className=" mr-1 mb-1 inline-flex" />
        by TechLab
      </p>
      <p className="text-gray-700">Copyright &copy; 2024 TechLab. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
